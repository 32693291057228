<template>
  <section class="all-templates-view" v-loading.fullscreen.lock="loading">
    <el-row type="flex" justify="center" align="center">
      <el-col :span="22">
        <el-row style="margin-bottom: 20px">
          <el-col :lg="5" :md="5" :sm="24" :xs="24">

            <h3 class="title fw-normal text-dark mb-0 ls-05">{{ 'e-Signs Market Place' }}</h3>
          </el-col>
          <el-col :lg="19" :md="19" :sm="24" :xs="24" style="text-align: right" class="top-buttons-wrapper">
            <el-select
                style="margin-right: 10px; width: 170px;" 
                size="medium"
                placeholder="Industrial Type"
                v-model="selectedIndustries"
                clearable
                multiple
                collapse-tags
                filterable
                remote
                >
                <el-option
                      v-for="item in allIndustries"
                      :key="item.name"
                      :label="item.name"
                      :value="item.name"
                      >
                </el-option>
            </el-select>
            <el-select 
                style="margin-right: 10px; width: 170px;" 
                size="medium"
                placeholder="Select Category"
                v-model="selectedCategories"
                clearable
                multiple
                collapse-tags
                filterable
                remote
                >
                <el-option
                      v-for="item in allCategories"
                      :key="item"
                      :label="item"
                      :value="item">
                </el-option>
            </el-select>
            <el-input class="search" placeholder="Search" v-model="search_string" clearable
              :style="getIsMobile ? 'width:130px' : 'width:170px;'"></el-input>
            &nbsp;&nbsp;
            <el-button @click="createApp">My Solutions</el-button>
            <el-dropdown style="border: none;  margin-left: 3px;">
                        <el-button size="mini" class="el-dropdown-link px-1">
                          <i class="el-icon-more" style="transform: rotate(90deg); "></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                          <router-link
                          to="/apps/installed">
                          <a  style="border: none">
                            <el-dropdown-item v-if="getCompanyDetails && getCompanyDetails._id != approvalsCompanyId" icon="el-icon-download" command="view">Installed Apps</el-dropdown-item>
                          </a>
                          </router-link>
                           
                            <a  style="border: none" @click="openApprovals" v-if="getCompanyDetails && getCompanyDetails._id == approvalsCompanyId"
                            >
                            <el-dropdown-item icon="el-icon-top-right" command="view">Approvals</el-dropdown-item>
                            </a>
                            
                            <!-- <a  style="border: none" @click="addIndustryDialog = true" v-if="this.checkPermissionByPermissionName('addApp')"
                            >
                            <el-dropdown-item icon="el-icon-plus" command="view">Add industry</el-dropdown-item>
                            </a> -->

                          <!-- <a @click="onEdit(scope.row)" style="border: none">
                            <el-dropdown-item icon="el-icon-edit-outline" command="edit">Edit</el-dropdown-item>
                          </a> -->
                          <!-- <a
                            v-if="scope.row.entity_type === 'GENERAL'"
                            @click="onAssignToCompany(scope.row)"
                            style="border: none"
                            ><el-dropdown-item
                              icon="el-icon-edit-outline"
                              command="edit"
                            >
                              Attached To Company Info</el-dropdown-item
                            ></a
                          >
                          <a @click="onDelete(scope.row)" style="border: none">
                            <el-dropdown-item icon="el-icon-delete" command="delete">Delete</el-dropdown-item>
                          </a> -->
                        </el-dropdown-menu>
                      </el-dropdown>
          </el-col>
        </el-row>
        <!-- {{ getAllApps }} -->
        <el-row :gutter="10" class="card-container" v-if="allApps.length">
          <el-col v-for="app of allApps" :key="app._id" :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
            <div class="card-container">
              <el-card class="box-card mb-1 card" shadow="hover">
                <div class="card-content">
                  <div class="left-content">
                    <img v-if="app.icon" :src="require('@/assets/img/icons/menu/' + app.icon)" class="imgSize" />
                    <img class="imgSize" v-else-if="app.version && app.version[0] && app.version[0].logo && app.version[0].logo.length > 0" :src="`${S3_BUCKET_URL}${app.version[0].logo[0]}`"/>
                    <img v-else src="require('@/assets/img/icons/menu/company-document-2.svg')" alt="menu"
                      class="imgSize" />
                    <div class="text-wrapper">
                      <div class="title-wrapper">
            <h5 style="margin-bottom: 0;">{{ app.name }}</h5>
            <p style="margin-bottom: 0;">{{ app.developed_by.name }}</p>
            <i class="el-icon-download"></i> {{ downloadsCount[app._id] }}
          </div>
                      <!-- <span> -->
                        <!-- <div class="rating-wrapper">
                          <el-rate 
                          :value="averageRating" 
                          :colors="colors" 
                          disabled
                          show-score
                          size="small"
                          score-template="3.5"
                          class="custom-rate"
                          />
                        </div> -->
                      <!-- </span> -->
                    </div>
                  </div>
                </div>
                <div class="text text-justify text-ellipsis mt-3" style="height: 50px;">
                  <span v-if="app.single_line_description">{{ app.single_line_description }}</span>
                  <span v-else>
                    Employee management involves the activities and performance of employees within an organization.
                  </span>
                </div>
                <div class="card-content flex-end">
                  <div class="right-content">
                    <el-button  size="mini" class="card-button" style="color: #F754A2;"
                      v-if="checkPermissionByPermissionName('cloneApp') && !alreadyInstalledAppsIds.includes(app._id)"
                      @click="install(app)" :disabled="getCompanyDetails && getCompanyDetails._id == approvalsCompanyId">Install</el-button>
                    <el-button size="mini" class="card-button" @click="appInfo(app)">More Info</el-button>
                  </div>
                </div>
              </el-card>
            </div>
          </el-col>
        </el-row>
        <el-row v-else>
          <el-col>
            <el-empty description="No application data."></el-empty>
          </el-col>
        </el-row>

      </el-col>
    </el-row>
    <el-row v-if="total">
      <el-col>
        <!-- <el-pagination :total="total" :v-model:page-size="pageSize" layout="prev, pager, next,total" :page-sizes="pagination" /> -->
        <!-- :small="small"
        :disabled="disabled"
        :background="background" -->
        <el-pagination :v-model:current-page="currentPage" :v-model:page-size="pageSize" class="custom-pagination"
          :page-sizes="[9, 18, 27, 54, 100]" layout="total, sizes, prev, pager, next, jumper" :total="total"
          @size-change="handleSizeChange" @current-change="handleCurrentChange" />

      </el-col>
    </el-row>

    <dialog-component
    :title="popupData.name"
    :visible="dialogVisible"
    width="35%" 
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    class="installApplicationPopUp">
      <div slot="title" class="dialog-header">
        <p style="color:#fff; font-size:16px;">{{ popupData.name }}</p>
        <!-- <i class="el-icon-close" @click="dialogVisible = false"></i> -->
      </div>
      <div class="dialog-body">
        <!-- <div class="division-one">
          <div class="slideshow">
            <el-carousel :interval="3000">
              <el-carousel-item
                v-for="(item, index) in popupData.version && popupData.version[0] && popupData.version[0].images"
                :key="index">
                <img :src="`${S3_BUCKET_URL}${item}`" alt="Slideshow Image">
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="info">
            <div class="sidemenu">
              <p>{{ popupData.description }}
              </p>
            </div>
          </div>
        </div> -->
        <div class="division-two">
          <h3>App Name<span style="color:red">*</span></h3>
          <el-input placeholder="Enter App name" v-model="popupData.newName"></el-input><br><br>
          <h3>Description</h3>
          <el-input type="textarea" :rows="2" placeholder="Please input" v-model="popupData.newDescription">
          </el-input>
          <el-checkbox style="margin-top: 5px;" v-model="popupData.IsInstallAsWorkspace">Install app as a new
            workspace</el-checkbox>
          <!-- <el-row class="block-col-2">
                    <el-col :span="12"><br>
                        <span class="demonstration">CurrentRole</span>
                        <el-dropdown>
                            <span class="el-dropdown-link">
                                <i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item icon="el-icon-plus">HR</el-dropdown-item>
                                <el-dropdown-item icon="el-icon-circle-plus">HrAdmin</el-dropdown-item>
                                <el-dropdown-item icon="el-icon-circle-plus-outline">HrManager</el-dropdown-item>
                                <el-dropdown-item icon="el-icon-check">Admin</el-dropdown-item>
                                <el-dropdown-item icon="el-icon-circle-check">Trainee</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </el-col>
                    <el-col :span="12"><br>
                        <span class="demonstration">CurrentRole</span>
                        <el-dropdown>
                            <span class="el-dropdown-link">
                                <i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item icon="el-icon-plus">HR</el-dropdown-item>
                                <el-dropdown-item icon="el-icon-circle-plus">HrAdmin</el-dropdown-item>
                                <el-dropdown-item icon="el-icon-circle-plus-outline">HrManager</el-dropdown-item>
                                <el-dropdown-item icon="el-icon-check">Admin</el-dropdown-item>
                                <el-dropdown-item icon="el-icon-circle-check">Trainee</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </el-col>
                </el-row><br>
                <el-checkbox v-model="checked">Install the application on premise</el-checkbox><br><br> -->

          <br /><br />
          <div class="buttons">
            <el-button @click="dialogVisible = false">Cancel</el-button>
            <el-button type="primary" @click="installApplication">Install</el-button>
          </div>
        </div>
      </div>
    </dialog-component>
    <dialog-component 
    :title="popupData.name"
    :visible="moreInfoDialogVisible" width="50%" 
    @before-close="ClosemoreInfoDialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    class="installApplicationPopUp">
      <div slot="title" class="dialog-header">
        <p style="color:#fff; font-size:16px;">{{ popupData.name }}</p>
        <!-- <i class="el-icon-close" @click="dialogVisible = false"></i> -->
      </div>
      <div class="dialog-body">
        <el-row>
          <el-col :span="20">
            <div>
          <div class="slideshow" >
            <el-carousel :interval="3000"  indicator-position="outside">
              <el-carousel-item
                v-for="(item, index) in popupData.version && popupData.version[0] && popupData.version[0].images"
                :key="index">
                <img :src="`${S3_BUCKET_URL}${item}`" alt="Slideshow Image">
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="info">
            <div class="sidemenu">
              <p>{{ popupData.description }}
              </p>
            </div>
          </div>
        </div>
          </el-col>
          <el-col :span="4">
            <div style="margin-top:30px">
              <ul>
                <li v-if="popupData.version && popupData.version[0] && popupData.version[0].entities"><b>Entities</b> : {{ popupData.version[0].entities.length }}</li>
                <li v-if="popupData.version && popupData.version[0] && popupData.version[0].menuManagementWithRoles"><b>Roles</b> : {{ popupData.version[0].menuManagementWithRoles.length }}</li>
                <!-- <li v-if="popupData.version && popupData.version[0] && popupData.version[0].document_templates"><b>Document Templates</b> : {{ popupData.version[0].document_templates.length }}</li> -->
                <li v-if="popupData.version && popupData.version[0] && popupData.version[0].version"><b>version</b> : {{ popupData.version[0].version}}</li>
                <li></li>
              </ul>
        </div>
          </el-col>
        </el-row>
      </div>
     
    </dialog-component>
    <el-dialog
          :visible.sync="appLoadingDialogVisible"
          width="35%"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :show-close="false"
          center
        >
          <div class="text-center">
            <img
              src="@/assets/img/importDataLoading.gif"
              class="import-loading-gif"
            />
            <p>Please wait</p>
            <h1>Your Application installation is in progress...</h1>
          </div>
    </el-dialog>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import PermissionsHelper from "@/mixins/permissionsHelper";
import NavigationHelper from "@/mixins/navigationHelper";
import config from "../../config/app";
import Swal from "sweetalert2";
export default {
  name: "Applications-List",
  data() {
    return {
      S3_BUCKET_URL: config.S3_BUCKET_URL,
      averageRating: 3.5, // The fixed average rating value
      colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
      dialogVisible: false,
      moreInfoDialogVisible:false,
      popupData: {
        checked: false,
        IsInstallAsWorkspace: true,
        newName: "",
        newDescription: '',
        images: [],
      },
      order_by: 'title',
      order_type: "asc",
      search_string: "",
      configureLoading: false,
      total: 0,
      activeStep: 0,
      currentPage: 1,
      pageSize: 0,
      pagination: [8, 16, 24, 32, 40],
      page: 1,
      newEntityModal: false,
      entityName: "",
      description: "",
      isAccount: false,
      entity_type: "GENERAL",
      modalLoading: false,
      allEntities: [],
      showAddGroupDialog: false,
      groupName: "",
      loadingSaveGroup: false,
      entityGroupsData: [],
      allApps: [],
      loading: true,
      allWorkspaces: [],
      allIndustries : [],
      selectedIndustryTypeName : '',
      selectedCategories : [],
      selectedIndustries : [],
      allCategories:[],
      appLoadingDialogVisible:false,
      mappingStatusDialogVisible:false,
      addIndustryDialog:false,
      currentWorkspace : {},
      userWorkspaceList : [],
      downloadsCount : {},
      alreadyInstalledAppsIds:[],
      approvalsCompanyId:'',
    };
  },
  mixins: [PermissionsHelper, NavigationHelper],
  computed: {
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getActiveWorkspace",
      "getUserType",
      "getWorkspaceList",
      "getAuthenticationStatus",
    ]),
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile"
    ]),
    ...mapGetters("company", [
      "getCompanyDetails",
      "getUpdateCompany",
      "getCompanyLogo",
      "getSingleCompanyLogo",
    ]),
    ...mapGetters("apps", [
      "getAllApps", 
      "getAppErrors", 
      "getInstallationStatus",
      "getAllCategories",
      "getAppInstallationErrors",
      "getInstalledApplications"
    ]),
    paginationProps() {
      return {
        pageSizes: this.pageSizes || [10, 20, 50]
      };
    }
  },
  async mounted() {
    await this.getCompanyInfo();
    if(this.getCompanyDetails && (this.getCompanyDetails.is_admin_clone || this.getCompanyDetails.is_cloned)){
      this.$notify.error({
                            title: "Error",
                            message: "You can't Access market place in an application"
                        })
      this.$router.push({ path: "/dashboard" });
    }
    else{
      this.allWorkspaces = this.getWorkspaceList.map(workspace => {
      let workspaceName = workspace.company_id.name
      return workspaceName
        })
      this.approvalsCompanyId = process.env.VUE_APP_APPROVALS_COMPANY_ID
      await this.fetchAllApps();
      this.fetchAllCategories();
      await this.fetchInstalledApplications();
    }
  },
  methods: {
    ClosemoreInfoDialogVisible(){
      this.moreInfoDialogVisible=false
    },
    async handleSizeChange(val) {
      // console.log(`${val} items per page`)
      this.loading = true;
      this.pageSize = parseInt(val);
    },
    async handleCurrentChange(val) {
      this.loading = true;
      // console.log(`current page: ${val}`)
      this.currentPage = parseInt(val);
    },

    async installApplication() {
      if (this.popupData.newName && this.popupData.description) {
        this.appLoadingDialogVisible = true;
        this.popupData.newName = this.popupData.newName.trim();

        // Check if the trimmed newName is empty or contains only spaces
        if (!this.popupData.newName) {
          this.appLoadingDialogVisible = false;
          return this.$notify.error({
            title: "Error",
            message: "Please enter a valid company name"
          });
        }
          await this.$store.dispatch("apps/installApplication", this.popupData);
          if(this.getInstallationStatus){
            this.dialogVisible = false
            this.appLoadingDialogVisible = false;
            this.$message.success(this.popupData.name + "  is installed");
            console.log("this.popupData",this.popupData)
            console.log("this.getinstallation",this.getInstallationStatus)
            if(this.popupData && this.popupData.IsInstallAsWorkspace){
              await this.changeWorkspace(this.getInstallationStatus.data.newCompanyId,this.getInstallationStatus.data.roles[0])
            }
            //this.$router.push({ path: "/dashboard" });
            //window.location.reload();
          }
          else{
            this.appLoadingDialogVisible = false
          return this.$notify.error({
            title: "Error",
            message: this.getAppInstallationErrors ||  'Error installing app'
          });
          }
      }
      else {
        this.$message("Please provide all the required Information");
      }
    },
    async changeWorkspace(newWorkspaceId,activeRole) {
      Swal.close();
      Swal.fire({
        title: "Your application has been successfully installed",
        text: "Do you want to switch to the new application?",
        icon: "success",
        showCancelButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.loadingAlert(10000);
          await this.$store.dispatch("auth/switchWorkspace", {
            currentWorkspaceId: newWorkspaceId,       
          });
          if (this.getAuthenticationStatus) {
            this.$store.commit("errors/reset", null, { root: true });
            this.currentWorkspace = this.getAuthenticatedUser.current_workspace;
            await this.fetchUserDataAfterSwitch();
            if (this.isWorkspaceIsBusiness(this.getActiveWorkspace)) {
              await this.getCompanyUserType(activeRole);
              await this.$store.dispatch("settings/fetchUserDashboardStatics");
            }
            await this.$store.dispatch("auth/fetchProfile");
            await this.$store.dispatch("settings/fetchUserDashboardStatics");
            this.$router.push({ path: "/" });
            this.$router.push({ path: "/documents" });
            Swal.close();
            window.location.reload();
            // eslint-disable-next-line no-debugger
            // debugger;
          }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          this.currentWorkspaceId = this.currentWorkspace.company_id;
          this.setActiveRole();
          window.location.reload()
        }
      });
    },
    async loadingAlert(timer = 1500) {
      Swal.close();
      let timerInterval;
      Swal.fire({
        title: "",
        html: "Please wait....",
        timer: timer,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      });
    },
    async setActiveRole() {
      this.activeRole =
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.activeRole &&
        this.getAuthenticatedUser.activeRole.userType_id
          ? this.getAuthenticatedUser.activeRole.userType_id
          : "";
    },
    async fetchUserDataAfterSwitch() {
      this.loading = true;
      await this.$store.dispatch(
        "auth/fetchUsersWithEmail",
        this.getAuthenticatedUser.email
      );
      this.loading = false;
      if (this.getWorkspaceList) {
        let info = await this.updateIndividualWorkspace(
          this.getActiveWorkspace
        );
        this.currentWorkspace = info.currentWorkspace;
        this.userWorkspaceList = info.userWorkspaceList;
      } else {
        this.userWorkspaceList = [];
      }
    },
    async getCompanyUserType(activeRole) {
      try {
        await this.$store.dispatch("auth/getUserTypeById",activeRole);
      } catch (err) {
        console.log(err);
        this.$notify.error({
          title: "Error",
          message: err,
        });
      }
    },
    async install(app) {
      this.popupData = { ...this.popupData, ...app };
      this.dialogVisible = true;

    },
    async appInfo(app){
      this.popupData = { ...this.popupData, ...app };
      this.moreInfoDialogVisible = true
    },
    async fetchAllApps() {
      this.loading = true;
      try {
        let params = {
          limit: this.pageSize > 0 ? this.pageSize : 9,
          page: this.currentPage,
          isOwner:false,
          isPublishedApplications:true,
        };
        
        if (this.search_string) {
          params.search_string = this.search_string;
        }
        // if (this.order_by) {
        //   params.order_by = this.order_by;
        // }
        if (this.order_type) {
          params.order_type = this.order_type;
        }
        if(this.selectedCategories){
          params.selectedCategories = this.selectedCategories;
        }
        if(this.selectedIndustries){
          params.selectedIndustries = this.selectedIndustries;
        }
        await this.$store.dispatch("apps/fetchAllApplications", params);
        if (this.getAllApps) {
          this.allApps = [...this.getAllApps.data];
          // this.allApps = this.allApps.filter(app => app.is_admin_clone == true || app.developed_by.name=='eSigns Team')
          this.total =
            this.getAllApps && this.getAllApps.total
              ? this.getAllApps.total
              : 0;
          this.downloadsCount = this.getAllApps && this.getAllApps.downloads_count ? this.getAllApps.downloads_count : {};
          this.currentPage = parseInt(this.getAllApps.page) || 1;

          this.loading = false;
        }
        else {
          this.loading = false;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchAllCategories(){
      try{
        await this.$store.dispatch("apps/fetchAllCategories");
        if(this.getAllCategories){
          this.allIndustries = [...this.getAllCategories.data]
          this.allCategories = [...new Set(this.allIndustries.map(item => item.categories).flat())];
        }
      }
      catch(err){
        console.log(err)
      }
    },
    beforeDestroy() {
      this.$store.commit(
        "navigationOpen/setCurrentMenu",
        {},
        {
          root: true
        }
      );
    },
    createApp() {
      this.$router.push({ path: "/apps/published" });
    },
    openApprovals() {
      if(!this.checkPermissionByPermissionName("addApp")){
         this.$notify.error({
            title: "Error",
            message: "You don't have permission to access this"
          });
      }
      else{
        this.$router.push({ path: "/apps/approvals" });
      }
    },
    async getCompanyInfo() {
      this.loading = true;
      try {
        await this.$store.dispatch(
          "company/fetchCompany",
          this.getActiveWorkspace.company_id
        );
        if (this.getCompanyDetails) {
          this.loading = false;
        }
      } catch (e) {
        this.loading = false;
        this.$notify.error({
          title: "Error",
          message: e,
        });
      }
    },
    async fetchInstalledApplications() {
      try {
        this.configureLoading = true;
        // let params = {
        //   get_all: true
        // };

        let params = {
        get_all: true,
        isOwner:true,
        installedApps : true
      };
      if (this.search_string) {
        params.search_string = this.search_string;
      }
      
      if (this.order_type) {
        params.order_type = this.order_type;
      }


        // await this.$store.dispatch("entities/fetchEntities", params);
        await this.$store.dispatch("apps/fetchAllInstalledApplications",params );
        if (this.getInstalledApplications) {
          this.alreadyInstalledAppsIds = this.getInstalledApplications.data.map(app=>app.app_id);
          this.total = this.getInstalledApplications && this.getInstalledApplications.total ? this.getInstalledApplications.total: 0;
          this.currentPage = parseInt(this.getInstalledApplications.page) || 1;
          this.configureLoading = false;
        } else {
          this.configureLoading = false;
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
  watch: {
    "currentPage": async function () {
      this.fetchAllApps();
    },
    "pageSize": async function () {
      this.fetchAllApps();
    },
    "search_string": async function() {
      this.fetchAllApps();
    },
    "selectedIndustries": async function() {
      this.fetchAllApps();
    },
    "selectedCategories": async function() {
      this.fetchAllApps();
    }

  }
};
</script>
<style scoped lang="scss">
.import-loading-gif {
  height: 30vh;
  width: 15vw;
}
.card-content {
  display: flex;
  justify-content: space-between;
}

.left-content {
  display: flex;
  align-items: center;
}

.right-content {
  margin-left: auto;
}

.el-card {
  height: 180px;
  font-size: 12px;
}

.el-card h5 {
  font-size: 14px;
}

.card-button {
  border-radius: 5px;
}
// .rating-wrapper{
//     width:120px;
// }
// .custom-rate .el-rate__icon {
//   width: 12px; /* Adjust this value to decrease the width of the stars */
//   height: 12px; /* Adjust this value to decrease the height of the stars */
// }
.custom-pagination {
    text-align: center;
    margin-top: 5px;
  }
.card-container {
  display: flex;
  flex-wrap: wrap;
}

.card {
  flex: 1;
}
.installApplicationPopUp .dialog-body {
    margin: 20px;
}

.installApplicationPopUp {
  .slideshow {
    flex-basis: 100%;
    height: 100%;
    margin: 3%;
    border: 1px solid #f7f0f0;
    border-radius: 5px;
    padding: 1%;

  }
  .el-carousel__indicators.is-arrow .el-carousel__indicator.is-active {
  background-color: #000; /* Set the background color to black */
}
  .slideshow img {
    width: 100%;
    height: 95%;
    object-fit: cover;
  }

  .info {
    flex-basis: 50%;
    height: 30%;
    margin: 2%;
  }

  .sidemenu {
    height: 108px;
    overflow-y: scroll;
    overflow-x: scroll;

    margin-right: 5px;
  }

  .sidemenu::-webkit-scrollbar {
    width: 0.7em;
  }

  .sidemenu::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    //background: #e1e1e1;
    //-webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
  }

  .sidemenu::-webkit-scrollbar-track {
    //-webkit-box-shadow: inset 0 0 6px #F5F5F5;
    // background-color: #F5F5F5;

  }

  .dialog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .dialog-body {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .division-one {
    // flex-basis: 100%;
    height: auto;
    overflow: auto;
    width:70%;
    //background-color: #f1f1f1;
    //border: 1px solid #ddd;
  }

  .division-two {
    // width: 100%;
    padding: 20px;
    //background-color: #fff;
    border: 1px solid #ddd;
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
  }

  .el-icon-arrow-down {
    font-size: 10px;
  }

  .demonstration {
    display: block;
    color: #8492a6;
    font-size: 14px;
    margin-bottom: 20px;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
  }
}

.float-right {
  float: right;
}

.imgSize {
  height: 4.5em;
  width: 4.5em;
  margin-right: 5px
}

.text-justify {
  text-align: justify;
  word-break: break-all;
}

.text-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-center {
  text-align: center !important;
}

.doc-title {
  cursor: pointer;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  line-height: 14px;
  text-align: left;
  color: #6c757d !important;
}

.activestatus {
  font-size: 14px;
  color: #6c757d !important;
}

.circle {
  position: relative;
  padding-left: 20px;
  text-transform: capitalize;
  font-size: 14px;

  &::after {
    content: "";
    position: absolute;
    top: 4px;
    left: 0;
    height: 10px;
    width: 10px;
    display: inline-block;
    border-radius: 50%;
  }

  &.active::after {
    background: #08ad36;
  }

  &.inactive::after {
    background: #f562b3;
  }

  &.deleted::after {
    background: #8b1702;
  }

  &.archived::after {
    background: #404040;
  }

  &.disable::after {
    background: #c4c4c4;
  }

  &.viewed::after {
    background: #2f80ed;
  }
}
</style>
<style lang="scss">
.top-buttons-wrapper {
  @media (max-width: 991.98px) {
    display: flex;
    margin-top: 20px;
  }
}

.new-entity-information {
  .el-dialog {
    width: 800px;
    padding-bottom: 15px;

    @media (max-width: 800px) {
      width: 700px;
      margin-top: 40px !important;
    }

    @media (max-width: 700px) {
      width: 600px;
      margin-top: 40px !important;
    }

    @media (max-width: 600px) {
      width: 500px;
      margin-top: 40px !important;
    }

    @media (max-width: 500px) {
      width: 400px;
      margin-top: 40px !important;
    }

    @media (max-width: 400px) {
      width: 300px;
      margin-top: 40px !important;
    }

    .entity-type-radio {
      @media (max-width: 400px) {
        &>label {
          &>span {
            padding-left: 5px !important;
            padding-right: 5px !important;
          }
        }
      }
    }
  }
}
</style>